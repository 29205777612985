import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import MainContent from '@/components/main-content';
import Project from '@/components/projects/project';
import { media } from '@/utils';
import smallDecor from '@/assets/decor-small.svg';
import '@/stylesheets/projects';

const Container = styled(MainContent)`
  margin: 2em auto;

  ${media.phone`
    margin: 5em auto 2em;
  `};
`;

const Title = styled.h1`
  font-weight: 900;
  line-height: 1.25;
  margin: 0 0 0 .25em;
  text-align: right;
  width: calc(33.33% - .5em);

  ${media.phone`
    margin: 0 0 .5em;
    text-align: left;
    width: auto;
  `}

  ${media.tablet`
    width: calc(50% - .5em);
  `};
`;

const Description = styled.p`
  margin: 0 0 0 calc(33.33% + .5em);
  width: calc(33.33% - 1em);

  ${media.phone`
    margin: 0;
    width: auto;
  `}

  ${media.tablet`
    margin: 0 0 0 calc(50% + .5em);
    width: calc(50% - 1em);
  `};

  &::after {
    background-image: url(${smallDecor});
    background-size: 100%;
    content: '';
    display: inline-block;
    height: 1em;
    transform: translate(.5em, .25em);
    width: 1em;
  }
`;

const ProjectsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 4em;

  ${media.phone`
    margin-top: 2em;
  `}
`;

const Projects = ({ data }) => {
  const { projects } = data.site.siteMetadata;

  return (
    <Container>
      <Title>Just a few projects</Title>
      <Description>These are some of the personal, open source, projects that I've been working on.</Description>
      <ProjectsContainer>
        {projects.map((project, i) => (
          <Project key={i} project={project} />
        ))}
      </ProjectsContainer>
    </Container>
  );
};

export const query = graphql`
  query ProjectsPageQuery {
    site {
      siteMetadata {
        projects {
          title
          poster
          color
          url
          tags
          description {
            short
          }
        }
      }
    }
  }
`;

export default Projects;
