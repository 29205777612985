import styled from 'styled-components';
import { media } from '@/utils';

export default styled.section`
  margin: auto;
  max-width: 65vw;

  ${media.phone`
    margin-top: 5em;
    max-width: calc(100% - 2em);
  `}

  ${media.tablet`
    max-width: calc(100% - 2em);
  `};
`;