import React from 'react';
import styled from 'styled-components';
import Tappable from 'react-tappable';

import { media } from '@/utils';

const Container = styled(Tappable)`
  background-color: ${props => props.color};
  background-image: url(${props => props.background});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: .25em;
  box-shadow:
    0 .125em .25em rgba(0, 0, 0, .125),
    0 .5em 1em rgba(0, 0, 0, .05);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: .5em;
  padding-top: 10em;
  transition: background-size 500ms;
  width: calc(33.33% - 1em);

  &:hover {
    background-size: 110%;
  }

  ${media.tablet`
    margin: .5em;
    width: calc(50% - 1em);
  `};

  ${media.phone`
    margin: 0;
    padding-top: 12em;
    width: auto;

    & + & {
      margin-top: 1em;
    }
  `}
`;

const Info = styled.div`
  background-color: rgba(255, 255, 255, .35);
  border-radius: 0 0 .25em .25em;
  padding: 1em;

  @supports (backdrop-filter: blur(1em)) {
    background-color: rgba(255, 255, 255, .35);
    backdrop-filter: blur(1em);
  }
`;

const Title = styled.h3`
  font-weight: 600;
  margin: 0 0 .5em;
  text-transform: uppercase;
`;

const Description = styled.p`
  margin: 0;
`;

const Tags = styled.ul`
  display: flex;
  flex-wrap: wrap;
  font-size: .65em;
  list-style: none;
  margin: 1em -.5em -.5em;
  padding: 0;
`;

const Tag = styled.li`
  background: rgba(0, 0, 0, .25);
  color: white;
  border-radius: .25em;
  margin: .5em;
  padding: 0 .5em;
`;

const openUrl = url => () => window.location.href = url;

const Project = ({ project }) => {
  return (
    <Container
      background={require(`../../assets/projects/${project.poster}`)}
      color={project.color}
      onTap={openUrl(project.url)}>
      <Info>
        <Title>{project.title}</Title>
        <Description>{project.description.short}</Description>
        <Tags>
          {project.tags.map(tag => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </Tags>
      </Info>
    </Container>
  );
};

export default Project;
